import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";

const StyledRelatedResources = styled.aside`
  width: calc(100% - -3.2rem);

  background-color: var(--grey-lightest);
  margin-left: -1.6rem;
  ${baseGridStyles}
  grid-row-gap: 0;
  padding: 0;
  align-items: start;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
    grid-row-gap: 0;
    width: 100%;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 2rem;
    display: block;
    grid-column: 1 / 13;
    grid-template-columns: repeat(4,1fr);
    grid-row-gap: 0;
    padding: 1.6rem;
    height: fit-content;
  }

  h2 {
    grid-column: span 4;
    padding: 1.6rem;
    text-align: left;
    margin-top: 0;
    padding: 1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      /* margin-top: 2.4rem; */
      grid-column: span 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 0 0 0;
      grid-column: span 4;
      padding: 1.6rem ;
    }
  }

  ol li h2 {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
    
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-left: 1.6rem;
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }
}
`

const RelatedResourceList = styled.ol`
  background-color: var(--grey-lightest);
  ${baseGridStyles}
  list-style-type: none;
  margin: 0;
  padding: 0 1.6rem;
  grid-row-gap: 0.8rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(4,1fr);
    grid-column: span 4;
    grid-row-gap: 1.6rem;
    padding-bottom: 1.6rem;
  }
`

const RelatedResourceItem = styled.li`
  grid-column: span 4;
  /* margin-left: 1.6rem; */
  padding-bottom: 1.2rem;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid var(--grey-light);
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 2;
    border-bottom: 1px solid var(--grey-light);
  }

  &:last-child {
    border-bottom: 0px solid var(--grey-light);
    padding-bottom: 0;
    
    h3 {
      margin-bottom: 1.6rem;
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: span 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-bottom: 0rem;
      grid-column: span 4;
    }
  }
  &:nth-last-child(2) {
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding-bottom: 0rem;
      border-bottom: 0px solid var(--grey-light);
    }
  }
  a,
  a:hover,
  a:focus {
    border-bottom: 0;
  }
  a:hover,
  a:focus {
    border-bottom: 0;
    h3 {
      color: var(--orange-mid);
    }
  }
`

export { StyledRelatedResources, RelatedResourceList, RelatedResourceItem }

import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
  ArticleAuthorInfo,
  ArticleH1,
} from "../styledComponents/article"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  StyledRelatedResources
} from "../styledComponents/styledRelatedResources"
import { StyledLatestResources } from "../styledComponents/styledLatestResources"
import CardList from "../components/CardList"


const BlogPostTemplate = ({ 
  data: { post, relatedPostsByTag, latestPosts },
  location,
}) => {
  const featuredImage = {
    publicURL: post.featuredImage?.node?.localFile?.publicURL,
  }
  
  return (
    <Layout location={location} displayNavBorder>
      <Seo 
        title={post.title} 
        description={post.excerpt.replace('<p>','').replace('</p>', '')} 
        image={featuredImage.publicURL}
        url={post.uri}
        /> 
      
      <Article
        className="resource-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <ArticleHeader>
          <ArticleH1 itemProp="headline">{parse(post.title)}</ArticleH1>
          <ArticleDate pubdate dateTime={post.dateGmt}><span className="publishedon">Published on</span> {post.date}</ArticleDate>
          <ArticleAuthorInfo className="author"><span>Published by TICG</span> </ArticleAuthorInfo>
          {/* <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={parse(post.title)} /> */}
        </ArticleHeader>

        {!!post.content && (
          <ArticleSection itemProp="articleBody">
            {parse(post.content, featuredImage)}
            {/* <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={post.title} /> */}
          </ArticleSection>
        )}

      

        {(relatedPostsByTag.nodes.length !== 0 && (
      <StyledRelatedResources>
        <h2>Related articles</h2>
        {(relatedPostsByTag.nodes.length && (
          <CardList posts={relatedPostsByTag.nodes} />
        )) ||
          ""}
      </StyledRelatedResources>
        ))}


      <StyledLatestResources>
        <h2>Other articles about <abbr title="Search Engine Optimsation">SEO</abbr>, <abbr title="User Experience or User Interface">UX/UI</abbr>, Design &amp; Technology</h2>
          <CardList posts={latestPosts.nodes} />
      </StyledLatestResources>
      </Article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $tagName: String!
    $id: String!
  ) {

    relatedPostsByTag: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 4
      skip: 0
      filter: {
        tags: { nodes: { elemMatch: { name: { eq: $tagName } } } }
        id: { nin: [$id] }
      }
    ) {
      nodes {
        excerpt
        uri
        id
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 720
                  quality: 100
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }

    latestPosts: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 7
      skip: 0
      filter: {
        id: { nin: [$id] }
      }
    ) {
      nodes {
        excerpt
        uri
        id
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 720
                  quality: 100
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
    # selecting the current post by id
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      dateGmt

      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }

      tags {
        nodes {
          name
          id
          uri
        }
      }
    }
  }
`

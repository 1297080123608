import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";

const StyledLatestResources = styled.aside`
  width: calc(100% - -3.2rem);
  margin-left: -1.6rem;
  ${baseGridStyles}
  grid-row-gap: 0;
  padding: 0;
  align-items: start;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
    grid-row-gap: 0;
    width: 100%;
    border-radius: 1rem;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 2rem;
    display: block;
    grid-template-columns: repeat(4,1fr);
    grid-row-gap: 0;
    padding: 0;
    height: fit-content;
  }

  h2 {
    grid-column: span 4;
    text-align: left;
    margin-top: 0;
    padding: 1.6rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
      /* margin-top: 2.4rem; */
      grid-column: span 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      background: none;
      padding: 2.4rem 0!important;

      margin: 0 0 0;
      grid-column: span 4;
      padding: 1.6rem ;
    }
  }

  ol {
    padding-top: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {

    }
    @media screen and (min-width: ${breakpoints.md}px) {

    }
    li h2 {
      
      padding-left: 0;
      padding-bottom: 0;
      margin-left: 0;
      @media screen and (min-width: ${breakpoints.md}px) {
        margin-left: 1.6rem;
      }
    }
  }
`

const LatestResourceList = styled.ol`
  background-color: var(--grey-lightest);
  ${baseGridStyles}
  list-style-type: none;
  margin: 0;
  padding: 0 1.6rem;
  grid-row-gap: 0.8rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(4,1fr);
    grid-column: span 4;
    grid-row-gap: 1.6rem;
    padding-bottom: 1.6rem;
    background: none;
    padding: 2.4rem 0;
  }
`

const LatestResourceItem = styled.li`
  grid-column: span 4;
  padding-bottom: 0;
  border-bottom: 1px solid var(--grey-light);
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 2;
    border-bottom: 1px solid var(--grey-light);
  }

  &:last-child {
    border-bottom: 0px solid var(--grey-light);
    padding-bottom: 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-bottom: 0rem;
    }
  }
  
  a,
  a:hover,
  a:focus {
    border-bottom: 0;
  }
  a:hover,
  a:focus {
    border-bottom: 0;
    h3 {
      color: var(--orange-mid);
    }
  }
`

export { StyledLatestResources, LatestResourceList, LatestResourceItem }
